import ENDPOINTS from '../../endpoints.json';

// This key has only Public Read permissions,
// and it can't and shouldn't be used for write/Internal/Admin operations
// https://docs.teamtailor.com/#authentication
const PUBLIC_API_KEY = '-nj1Cqg3cZVtIYykJELehR3wp6EDfRwxoqzQB28u';

export type Job = {
  id: string;
  title: string;
  url: string;
  location: { id: string; name: string };
};

export default async function getJobs(): Promise<Job[]> {
  // do request
  const url = ENDPOINTS.teamtailor;

  if (!url) {
    throw new Error('Team tailor url missing. Check your endpoints.json file!');
  }

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token=${PUBLIC_API_KEY}`,
      'X-Api-Version': '20210806', // get api version here https://docs.teamtailor.com/#changelog
    },
  };

  const request = await fetch(`${url}/jobs?include=location`, options);
  const response = await request.json();

  if (response.error) {
    throw new Error(response.error);
  }

  if (!Array.isArray(response?.data)) return [];

  const list: Job[] = response.data.map(async (job) => {
    const locationId = job.relationships?.location?.data?.id || '1';
    const locationUrl = `${url}/locations/${locationId}`;

    const locationRequest = await fetch(locationUrl, options);
    const location = await locationRequest.json();

    const { id = '', attributes } = location?.data || {};
    const { city = 'London', country = 'UK' } = attributes || {};
    return {
      id: job.id,
      title: job.attributes?.title || '',
      location: { id, name: `${city}, ${country}` },
      url: job.links?.['careersite-job-url'] || '',
    };
  });

  const jobs = await Promise.all(list);
  return jobs;
}
