import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import deepEqual from 'deep-equal';

import COLORS, { Color } from '../styles/colors';

const TOLERANCE = 5;

const gradient = (direction: string, color: Color) => css`linear-gradient(
  to ${direction},
  ${color.opacity(0.08)},
  ${color.opacity(0)}
);

`;
type ContainerProps = {
  $position: keyof ShadowPosition;
  $size: number;
  $color: Color;
};
const Item = styled.div<ContainerProps>`
  position: absolute;
  ${(props) => {
    switch (props.$position) {
      case 'left':
        return css`
          left: 0px;
          height: 100%;
          width: ${props.$size}px;
          background: ${gradient('right', props.$color)};
        `;
      case 'right':
        return css`
          right: 0px;
          height: 100%;
          width: ${props.$size}px;
          background: ${gradient('left', props.$color)};
        `;
      case 'bottom':
        return css`
          bottom: 0px;
          width: 100%;
          height: ${props.$size}px;
          background: ${gradient('top', props.$color)};
        `;
      case 'top':
      default:
        return css`
          top: 0px;
          width: 100%;
          height: ${props.$size}px;
          background: ${gradient('bottom', props.$color)};
        `;
    }
  }}
  flex: 0 0 auto;
  z-index: 10;
`;

type ScrollShadowProps = {
  size?: number;
  color?: Color;
  element: MutableRefObject<HTMLElement | null>;
};

type ShadowPosition = {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
};

const ScrollShadow = ({
  size = 30,
  color = COLORS.black,
  element,
}: ScrollShadowProps) => {
  const [shadow, setShadow] = useState<ShadowPosition>({
    top: false,
    bottom: false,
    left: false,
    right: false,
  });

  const listener = useCallback(() => {
    const el = element.current;
    if (!el) return;
    const {
      clientHeight,
      clientWidth,
      scrollHeight,
      scrollWidth,
      scrollLeft,
      scrollTop,
    } = el;
    const bottom = clientHeight < scrollHeight - scrollTop - TOLERANCE;
    const top = scrollTop > TOLERANCE;
    const right = clientWidth < scrollWidth - scrollLeft - TOLERANCE;
    const left = scrollLeft > TOLERANCE;

    const value = { top, bottom, left, right };
    setShadow((prev) => (deepEqual(prev, value) ? prev : value));
  }, [element]);

  useEffect(() => {
    const el = element.current;
    listener();

    el?.addEventListener('scroll', listener);
    window?.addEventListener('resize', listener);
    return () => {
      el?.removeEventListener('scroll', listener);
      window?.removeEventListener('resize', listener);
    };
  }, [element, listener]);

  return (
    <>
      {shadow.top ? (
        <Item $position="top" $size={size} $color={color} />
      ) : (
        false
      )}
      {shadow.bottom ? (
        <Item $position="bottom" $size={size} $color={color} />
      ) : (
        false
      )}
      {shadow.left ? (
        <Item $position="left" $size={size} $color={color} />
      ) : (
        false
      )}
      {shadow.right ? (
        <Item $position="right" $size={size} $color={color} />
      ) : (
        false
      )}
    </>
  );
};

export default ScrollShadow;
