import React, { memo, useRef } from 'react';
import styled from 'styled-components';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowData,
} from '@tanstack/react-table';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import ScrollShadow from './ScrollShadow';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    width?: 'auto';
  }
}

const Container = styled.div`
  background: ${COLORS.white.css};
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 6px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Inner = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
  width: 100%;
  position: relative;

  & tbody {
    position: relative;

    &[data-fade='true'] {
      &:after {
        z-index: 1;
        content: '';
        height: 180px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        background: linear-gradient(
          ${COLORS.white.opacity(0)},
          ${COLORS.white.opacity(1)}
        );
      }
    }

    & tr:last-child {
      border-bottom: none;
      & td {
        border-bottom: none;
      }
    }
  }
  & caption {
    ${TYPO.p2};
    caption-side: bottom;
    font-size: 14px;
    padding: 12px;
    position: relative;
    z-index: 2;
  }
  & tr {
    border: 1px solid ${COLORS.shades.s200.css};
    border-left: none;
    border-right: none;
    vertical-align: middle;
    display: table-row;
    min-height: 42px;
  }

  & thead tr {
    background: ${COLORS.blocks.background.css};
    min-height: 0;
    height: 24px;
    padding: 3px 12px;
    border-top: none;
  }
  & th {
    ${TYPO.p2}
    text-align: left;
    font-weight: 500;
    text-transform: capitalize;
    padding: 3px 12px;
  }

  & td {
    border: 1px solid ${COLORS.shades.s200.css};
    border-left: none;
    padding: 12px;
    ${TYPO.p2}

    &:last-child {
      border-right: none;
    }
  }
`;
type DisplayTableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  caption?: string;
  fade?: boolean;
};
function DisplayTable<T>({
  data,
  columns,
  caption,
  fade,
}: DisplayTableProps<T>) {
  const ref = useRef(null);
  const table = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <ScrollShadow element={ref} />
      <Inner ref={ref}>
        <Table>
          {caption && <caption>{caption}</caption>}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const width = header.getSize();
                  const { meta } = header.column.columnDef;

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        minWidth: `${width}px`,
                        width:
                          meta?.width === 'auto' ? undefined : `${width}px`,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody data-fade={fade}>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const size = cell.column.getSize();
                    const { meta } = cell.column.columnDef;

                    return (
                      <td
                        key={cell.id}
                        style={{
                          minWidth: `${size}px`,
                          width:
                            meta?.width === 'auto' ? undefined : `${size}px`,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Inner>
    </Container>
  );
}

export default memo(DisplayTable);
