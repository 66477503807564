import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';
import Heading from './Heading';
import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import Icon, { IconName } from './Icon';
import { divider } from '../styles/divider';

type ContainerProps = {
  $variant: string; // 'Dark' | 'Light';
};
const Container = styled(Grid)<ContainerProps>`
  margin-top: 60px;
  padding: 108px 12px 84px;
  position: relative;
  ${divider('center')}
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  ${columns(2, 10)}

  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: center;
  padding: 24px 0;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show, 0, undefined, 0.05)};

  ${BREAKPOINTS.max.medium`
  ${columns(1, 12)}
    `}
`;

const Item = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${BREAKPOINTS.max.small`
    flex: 0 0 100%;
    align-items: center;
    `}
`;

const ValueIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${COLORS.brand.regular.css};

  & svg {
    stroke-width: 1px;
  }
  ${BREAKPOINTS.max.small`
  justify-content: center;
  `}
`;

const Info = styled.div`
  margin-top: 18px;
  text-align: left;
  ${BREAKPOINTS.max.small`
  text-align: center;
  `}
`;

const ItemName = styled.div`
  ${TYPO.h3}
  font-size:15px;
  ${BREAKPOINTS.max.small`
 font-size:14px;
  `}
`;
const ItemContent = styled.div`
  ${TYPO.p2}
  margin-top: 6px;
  color: ${COLORS.shades.s400.css};
`;

type ValuesWithIconsProps = {
  data: BlockMediaCollectionFragment;
};

function ValuesWithIcons({ data }: ValuesWithIconsProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container $variant={data.theme}>
      <Heading
        heading={data.heading}
        byline={data.byline}
        show={inView}
        ref={inViewRef}
        headingLevel="h2"
      />
      <Content $show={inView}>
        {data.mediaCollection.items.map((value: MediaItemFragment) => (
          <Item key={value.heading}>
            <ValueIcon>
              {value.media && (
                <Image
                  src={value.media.url}
                  alt={value.heading}
                  height={60}
                  width={60}
                />
              )}
              {value.icon && (
                <Icon name={value.icon as IconName} width={30} height={30} />
              )}
            </ValueIcon>
            <Info>
              <ItemName>{value.heading}</ItemName>
              <ItemContent>{value.content}</ItemContent>
            </Info>
          </Item>
        ))}
      </Content>
    </Container>
  );
}

export default memo(ValuesWithIcons);
