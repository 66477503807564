import React from 'react';
import styled from 'styled-components';

import { BlockBlogPostFragment, BlogPostFragment } from '../schemas/api';

import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import BlogPostPreview from './BlogPostPreview';
import Heading from './Heading';

const Container = styled(Grid)`
  padding: 60px 12px 60px;
  & > div {
    max-width: 1024px;
    row-gap: 64px;
  }

  ${BREAKPOINTS.max.medium`
     ${columns(1, 12)};

     column-gap: 0;
  `}
`;

const Content = styled.div`
  ${columns(1, 12)};
  ${grid(12)};
  row-gap: 36px;
  ${BREAKPOINTS.max.medium`
      row-gap: 36px;
  `}
`;

type BlogCollectionProps = {
  data: BlockBlogPostFragment;
  posts: BlogPostFragment[];
  page: string;
};

export default function BlogCollection({
  posts,
  page,
  data,
}: BlogCollectionProps) {
  return (
    <Container>
      <Heading
        show
        heading={data.heading}
        byline={data.byline}
        headingLevel="h2"
      />
      <Content>
        {posts.map((post) => {
          return (
            <BlogPostPreview
              post={post}
              href={`/${page}/${post.slug}`}
              key={post.sys.id}
            />
          );
        })}
      </Content>
    </Container>
  );
}
