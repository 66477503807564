import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockLogoFragment } from '../schemas/api';

import Heading, { HeadingLevel } from './Heading';
import Image from './Image';
import useInview from '../hooks/useInview';

import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import LinkJSX from './Link';
import { spacing } from '../styles/blocks';

type ContainerProps = {
  $spacing: string;
};
const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
`;

type LogosPros = { $span: number; $gap: number; $columns: number };

const Logos = styled.div<LogosPros>`
  ${(props) => {
    const start = props.$columns
      ? Math.floor((12 - props.$columns) / 2) + 1
      : 1;
    const span = props.$columns || 12;
    return columns(start, span);
  }};
  align-items: center;
  justify-content: center;

  ${(props) => grid(props.$span || 7)}
  padding:12px 12px;

  ${BREAKPOINTS.max.small`
  ${columns(1, 12)}
  ${(props) => grid(props.$span ? Math.ceil(props.$span / 2) : 4)};
  column-gap: 36px;
`}

  ${BREAKPOINTS.max.extraSmall`
${grid(2)};
column-gap: 48px;
padding:12px 24px;
`}

  column-gap: ${(props) => props.$gap || 48}px;
  row-gap: ${(props) => props.$gap || 48}px;

  & img {
    &:hover {
      opacity: 0.7;
    }
  }
`;

type BlockLogoProps = {
  data: BlockLogoFragment;
};
function BlockLogo({ data }: BlockLogoProps) {
  const [inViewRef, inView] = useInview({});
  const logos = data.logos?.items;

  return (
    <Container $spacing={data.spacing}>
      <Heading
        heading={data.heading}
        byline={data.byline}
        show={inView}
        ref={inViewRef}
        headingLevel={(data.headingSize as HeadingLevel) || 'h3'}
        spacing="small"
      />
      {Array.isArray(logos) && !!logos.length && (
        <Logos
          $span={data.rowSpan || logos.length}
          $gap={data.logosGap}
          $columns={data.columnSpan}
        >
          {logos.map((d) => {
            return (
              <LinkJSX link={{ type: 'external', url: d.url }} key={d.name}>
                <Image
                  src={d.logo.url}
                  width={d.logo.width}
                  height={d.logo.height}
                  alt={d.name}
                  style={{ width: '100%', height: 'auto' }}
                />
              </LinkJSX>
            );
          })}
        </Logos>
      )}
    </Container>
  );
}

export default memo(BlockLogo);
